import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, PLATFORM_ID, signal } from '@angular/core';
import { catchError, map, Observable, retry, throwError } from 'rxjs';
import { OrderAdapter } from '../adapters/order.adapter';
import { OrderResponse, OrderResponseApi } from '../interfaces/orden.interface';
import { ApplicationLog, Log } from '../interfaces/log.interface';
import { isPlatformServer } from '@angular/common';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private readonly _http = inject(HttpClient)
  private readonly platformId = inject(PLATFORM_ID)
  uuid!: string

  $total = signal<number>(0);

  constructor() {
    if (isPlatformServer(this.platformId)) {
      this.uuid = uuidv4().replaceAll('-', '')
    }
  }

  getOrder(orderId: string): Observable<OrderResponse> {
    console.log(`---- RESQUEST: ${environment.orderApi}/paynow/app/v1/payment/order/${orderId}/angular/get -----`);
    return this._http.get<OrderResponseApi>(`${environment.orderApi}/paynow/app/v1/payment/order/${orderId}/angular/get`).pipe(
      map((response) => OrderAdapter(response, orderId)),
      retry(3),
      catchError(this.handleError)
    )
  }

  private handleError(error: HttpErrorResponse) {
    const responseError = {
      title: error.error?.message ? 'Información no encontrada' : 'Error en el servicio',
      message: 'Ocurrio un error al establecer la conexión con el servidor. Por favor intentalo más tarde.',
      success: false,
      ...error.error,
    }
    return throwError(() => responseError);
  }

  createLog(logData: Log) {
    console.log(`${this.formatedDate} | Pagos Web | ${this.uuid} | ${logData.process} | ${JSON.stringify(logData.request)} | ${JSON.stringify(logData.response)}`)
  }

  get formatedDate() {
    const date = new Date()
    const yyyy = date.getFullYear();
    const MM = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    const HH = String(date.getHours()).padStart(2, '0');
    const mm = String(date.getMinutes()).padStart(2, '0');
    const ss = String(date.getSeconds()).padStart(2, '0');

    return `${yyyy}-${MM}-${dd} ${HH}:${mm}:${ss}`;
  }

  createApplicationLog(log: ApplicationLog) {
    console.log(`${JSON.stringify(log.event)}`);
  }

}
