import { OrderResponse, OrderResponseApi } from "../interfaces/orden.interface";
import { validateURL } from "../utils/validateUrl";

export const OrderAdapter = (response: OrderResponseApi, orderId: string): OrderResponse => ({
  success: response.success,
  orderId,
  message: response.message,
  ...(response.data && {
    aplication: {
      ...response.data.saleInfo.aplication,
      storeImage: validateURL(response.data.saleInfo.aplication.storeImage)
    },
    credentials: {
      ...response.data.saleInfo.credentials,
    },
    orden: {
      ...response.data.saleInfo.orden,
      products: response.data.saleInfo.orden.products.map((p) => ({
        ...p,
        image: validateURL(p.image)
      }))
    },
    pluggin: { ...response.data.plugin, orderId },
    pma: response.data.tk
  }),
  ...(response.data?.saleInfo.shipment && { shipment: response.data.saleInfo.shipment }),
  ...(response.data?.expiryDate && { expiryDate: new Date(response.data.expiryDate) }),
})
